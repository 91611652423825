<script setup lang="ts"></script>

<template>
  <div class="flex items-center gap-2 shrink-0">
    <div class="w-6">
      <BrandsMarinadeIcon :show-tooltip="false" />
    </div>
    Marinade
    <tippy to="parent" :hide-on-click="false" interactive>
      <strong>Media sponsor:</strong> Medium Rare Foundation<br />
      <strong>Country:</strong> Republic of Panama<br />
      <strong>Contact:</strong>
      <a
        target="_blank"
        href="mailto:mediumrarefoundation@gmail.com"
        class="underline"
        >mediumrarefoundation@gmail.com</a
      >
    </tippy>
  </div>
</template>
