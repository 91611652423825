/**
 * This file was generated by kysely-codegen.
 * Please do not edit it manually.
 */

import type { ColumnType } from "kysely";

export enum AdminappCampaignSeriesType {
  Attribution = "attribution",
  Awareness = "awareness",
}

export enum AdminappCampaignWeekState {
  Approved = "approved",
  Canceled = "canceled",
  Completed = "completed",
  DeprecatedLive = "deprecated_live",
  Draft = "draft",
  Evaluating = "evaluating",
  Expired = "expired",
  Published = "published",
  Review = "review",
}

export enum AdminappPrivilege {
  ApproveCampaignWeek = "approveCampaignWeek",
  CreateAdvertiser = "createAdvertiser",
  CreateCampaignSeries = "createCampaignSeries",
  GetAdvertiser = "getAdvertiser",
  HasSuperpowers = "hasSuperpowers",
  ListAdvertisers = "listAdvertisers",
  ListBlobs = "listBlobs",
  ListUsers = "listUsers",
  ManageBlobs = "manageBlobs",
  ObtainSuperpowers = "obtainSuperpowers",
  PublishCampaignWeek = "publishCampaignWeek",
  SaveWeekDraft = "saveWeekDraft",
  SendCampaignWeekToReview = "sendCampaignWeekToReview",
  SpoofCreator = "spoofCreator",
  UpdateAdvertiserMember = "updateAdvertiserMember",
  UpdateCampaignWeekFunded = "updateCampaignWeekFunded",
  UpdateCreatorEligibility = "updateCreatorEligibility",
  UseSupportTools = "useSupportTools",
  VerifyUserWallet = "verifyUserWallet",
}

export enum AdminappRole {
  Advertiser = "advertiser",
  AdvertiserManager = "advertiserManager",
  AdvertiserSupport = "advertiserSupport",
  CreatorSupport = "creatorSupport",
  GlobalSuperuser = "globalSuperuser",
}

export enum AdminappRoleScope {
  Advertiser = "advertiser",
  Global = "global",
}

export enum AdminappStakingProgram {
  MarinadeLiquid = "Marinade liquid",
  MarinadeNative = "Marinade native",
}

export enum CreatorappCampaignWeekUserEligibilityState {
  Eligible = "eligible",
  Excluded = "excluded",
  Ineligible = "ineligible",
  Pending = "pending",
  Unknown = "unknown",
}

export enum CreatorappWalletState {
  Approved = "approved",
  New = "new",
  Verified = "verified",
}

export enum CreatorappWalletVerificationType {
  Auto = "auto",
  Manual = "manual",
}

export enum GlobalLoginTypeEnum {
  Google = "google",
  LoginLink = "login_link",
}

export type ArrayType<T> = ArrayTypeImpl<T> extends (infer U)[]
  ? U[]
  : ArrayTypeImpl<T>;

export type ArrayTypeImpl<T> = T extends ColumnType<infer S, infer I, infer U>
  ? ColumnType<S[], I[], U[]>
  : T[];

export type Generated<T> = T extends ColumnType<infer S, infer I, infer U>
  ? ColumnType<S, I | undefined, U>
  : ColumnType<T, T | undefined, T>;

export type Int8 = ColumnType<string, bigint | number | string, bigint | number | string>;

export type Json = JsonValue;

export type JsonArray = JsonValue[];

export type JsonObject = {
  [x: string]: JsonValue | undefined;
};

export type JsonPrimitive = boolean | number | string | null;

export type JsonValue = JsonArray | JsonObject | JsonPrimitive;

export type Numeric = ColumnType<string, number | string, number | string>;

export type Timestamp = ColumnType<Date, Date | string, Date | string>;

export interface AdminappAdvertiserAvailableCurrencies {
  advertiser_id: string;
  currency: string;
}

export interface AdminappAdvertisers {
  advertiser_id: string;
  basic_eval_id: number | null;
  campaign_default_description: Json | null;
}

export interface AdminappAdvertiserTwitterTokens {
  access_token: string;
  created_at: Generated<Timestamp>;
  refresh_token: string;
  updated_at: Generated<Timestamp>;
  user_id: string;
}

export interface AdminappAsset {
  created_at: Generated<Timestamp>;
  data: Buffer;
  data_mime_type: string;
  id: Generated<string>;
  name: string;
  updated_at: Generated<Timestamp>;
}

export interface AdminappCampaignSeries {
  advertiser_id: string;
  created_at: Generated<Timestamp>;
  deleted: Generated<boolean>;
  id: Generated<string>;
  name: string;
  reward_currency: string;
  type: AdminappCampaignSeriesType;
  updated_at: Generated<Timestamp>;
}

export interface AdminappCampaignWeekAsset {
  advertiser_id: string;
  asset_id: string;
  campaign_week_id: string;
  created_at: Generated<Timestamp>;
  updated_at: Generated<Timestamp>;
}

export interface AdminappCampaignWeekPaymentRequests {
  amount: Numeric;
  campaign_week_id: string;
  created_at: Generated<Timestamp>;
  currency: string;
  expires_at: Timestamp;
  fee: Numeric;
  id: Generated<string>;
  is_paid: Generated<boolean>;
  token: Generated<string>;
  updated_at: Generated<Timestamp>;
}

export interface AdminappCampaignWeeks {
  admin_eval_id: number | null;
  advertiser_id: string;
  annotation_instructions_html: string | null;
  annotation_instructions_json: Json | null;
  blockchain_transaction_id: string | null;
  campaign_series_id: string;
  created_at: Generated<Timestamp>;
  deleted: Generated<boolean>;
  description_html: string | null;
  description_json: Json | null;
  extended_expiration: Generated<boolean>;
  funded_amount: Generated<Numeric>;
  id: Generated<string>;
  is_funded: Generated<boolean>;
  name: Generated<string>;
  rewards_expiry: Generated<number>;
  staking_from_slot: Int8 | null;
  staking_min_amount: Numeric | null;
  staking_programs: ArrayType<AdminappStakingProgram> | null;
  staking_to_slot: Int8 | null;
  start_date: Timestamp;
  state: AdminappCampaignWeekState;
  suggested_donts: Generated<string[]>;
  suggested_dos: Generated<string[]>;
  /**
   * The title of the campaign week shown to the creators
   */
  title: Generated<string>;
  updated_at: Generated<Timestamp>;
  usd_conversion_rate: Numeric | null;
  weekly_post_cap: Generated<number>;
}

export interface AdminappCampaignWeekSegments {
  budget: Numeric | null;
  campaign_uuid: Generated<string>;
  campaign_week_id: string;
  order_index: number;
  reused: boolean;
  segment_id: string | null;
}

export interface AdminappLoginTokens {
  created_at: Generated<Timestamp>;
  expires_at: Timestamp;
  /**
   * sha256 hash of token secret. Used so that person with access to database cannot use tokens directly.
   */
  hash: string;
  login_token_id: Generated<string>;
  user_id: string;
}

export interface AdminappRolePrivileges {
  created_at: Generated<Timestamp>;
  privilege: AdminappPrivilege;
  role: AdminappRole;
  updated_at: Generated<Timestamp>;
}

export interface AdminappSegments {
  advertiser_id: string;
  apy_bonus: Numeric | null;
  cpm_protection: Numeric | null;
  created_at: Generated<Timestamp>;
  deleted: Generated<boolean>;
  geo_targeting_continents: Generated<string[] | null>;
  geo_targeting_countries: Generated<string[] | null>;
  geo_targeting_global: Generated<boolean>;
  id: Generated<string>;
  min_account_age_days: number | null;
  min_followers: number | null;
  name: string;
  signup_bonus: Numeric | null;
  signup_bonus_dedupe_id: string | null;
  summary: string;
  updated_at: Generated<Timestamp>;
}

export interface AdminappUserRoles {
  advertiser_id: string | null;
  created_at: Generated<Timestamp>;
  id: Generated<Int8>;
  role: AdminappRole;
  scope: AdminappRoleScope;
  updated_at: Generated<Timestamp>;
  user_id: string;
}

export interface AdminappUsers {
  created_at: Generated<Timestamp>;
  deleted: Generated<boolean>;
  email: string;
  has_logged_in: Generated<boolean>;
  id: Generated<string>;
  name: string | null;
  updated_at: Generated<Timestamp>;
}

export interface AdminappUserSpoofing {
  created_at: Generated<Timestamp>;
  spoofed_user_id: string;
  updated_at: Generated<Timestamp>;
  user_id: string;
}

export interface AnalyticsApiRequest {
  definition: Json;
  parameters: Json | null;
  request_id: Generated<number>;
  updated_at: Generated<Timestamp | null>;
}

export interface AnalyticsCampaign {
  basic_eval_id: number;
  campaign_id: string;
  campaign_label: string;
  date_end: Timestamp | null;
  date_maturity: Timestamp | null;
  date_start: Timestamp | null;
  parameters: Json;
  updated_at: Generated<Timestamp | null>;
}

export interface AnalyticsRawMedia {
  body: Json;
  media_key: string;
  updated_at: Generated<Timestamp | null>;
}

export interface AnalyticsRawTweet {
  body: Json;
  created_at: Timestamp | null;
  id: Int8;
  is_deleted: Generated<boolean | null>;
  is_hidden: Generated<boolean | null>;
  updated_at: Generated<Timestamp | null>;
}

export interface AnalyticsRawUser {
  body: Json;
  id: Int8;
  updated_at: Generated<Timestamp | null>;
}

export interface AnalyticsTopic {
  client_name: string | null;
  client_relation: string | null;
  query: string;
  title: string;
  topic_id: Generated<number>;
  updated_at: Generated<Timestamp | null>;
  valid_until: Timestamp | null;
}

export interface AnalyticsTweet {
  author_id: Int8 | null;
  bookmark_count: number | null;
  conversation_id: Int8 | null;
  impression_count: number | null;
  is_deleted: Generated<boolean | null>;
  is_editable: boolean | null;
  is_edited: boolean | null;
  is_hidden: Generated<boolean | null>;
  lang: string | null;
  like_count: number | null;
  quote_count: number | null;
  quoted_tweet: Int8 | null;
  replied_to_tweet: Int8 | null;
  reply_count: number | null;
  retweet_count: number | null;
  retweeted_tweet: Int8 | null;
  scraper_name: string | null;
  tweet_age_hours: Numeric | null;
  tweet_created_at: Generated<Timestamp | null>;
  tweet_fetched_at: Timestamp | null;
  tweet_id: Int8;
  tweet_text: string | null;
}

export interface AnalyticsTweetEn {
  author_id: Int8 | null;
  bookmark_count: number | null;
  conversation_id: Int8 | null;
  impression_count: number | null;
  lang: string | null;
  like_count: number | null;
  quote_count: number | null;
  quoted_tweet: Int8 | null;
  replied_to_tweet: Int8 | null;
  reply_count: number | null;
  retweet_count: number | null;
  tweet_age_hours: Numeric | null;
  tweet_created_at: Timestamp | null;
  tweet_fetched_at: Timestamp | null;
  tweet_id: Int8 | null;
  tweet_text: string | null;
}

export interface AnalyticsTweetEval {
  body: Json | null;
  eval_id: number;
  tweet_id: Int8;
  updated_at: Generated<Timestamp | null>;
}

export interface AnalyticsTweetEvalDef {
  definition: string;
  eval_id: Generated<number>;
  /**
   * human, gpt-4o
   */
  evaluator: string;
  parameters: Json | null;
  result_schema: Json | null;
  title: string;
  updated_at: Generated<Timestamp | null>;
}

export interface AnalyticsTweetMetrics {
  bookmark_count: number | null;
  impression_count: number | null;
  like_count: number | null;
  metrics_fetched_at: Timestamp;
  quote_count: number | null;
  reply_count: number | null;
  retweet_count: number | null;
  tweet_created_at: Timestamp;
  tweet_id: Int8;
  updated_at: Generated<Timestamp | null>;
}

export interface AnalyticsTweetMetricsRecent {
  bookmark_count: number | null;
  impression_count: number | null;
  like_count: number | null;
  metrics_fetched_at: Timestamp | null;
  quote_count: number | null;
  reply_count: number | null;
  retweet_count: number | null;
  tweet_age_hours: Numeric | null;
  tweet_created_at: Timestamp | null;
  tweet_id: Int8 | null;
}

export interface AnalyticsTweetReference {
  reference_type: string | null;
  referenced_id: Int8 | null;
  tweet_id: Int8 | null;
}

export interface AnalyticsTweetReward {
  campaign_id: string;
  impact_points: number | null;
  is_in_top_n: boolean | null;
  last_run_id: number | null;
  quality_score: number | null;
  receives_reward: boolean | null;
  reward_amount: Numeric | null;
  reward_weight: number | null;
  tweet_id: Int8;
  updated_at: Timestamp | null;
}

export interface AnalyticsTweetRewardCandidate {
  campaign_id: string;
  impact_points: number | null;
  is_in_top_n: boolean | null;
  last_run_id: number | null;
  quality_score: number | null;
  receives_reward: boolean | null;
  reward_amount: Numeric | null;
  reward_weight: number | null;
  tweet_id: Int8;
  updated_at: Timestamp | null;
}

export interface AnalyticsTweetState {
  basic_eval_campaigns: string[] | null;
  is_eligible: boolean | null;
  sent_reward: Json | null;
  tweet_id: Int8;
  updated_at: Generated<Timestamp | null>;
  user_claimed: boolean | null;
  user_got_paid: boolean | null;
  user_selected_campaign: string | null;
}

export interface AnalyticsTweetStateAudit {
  basic_eval_campaigns: string[] | null;
  is_eligible: boolean | null;
  operation: string;
  sent_reward: Json | null;
  stamp: Timestamp;
  tweet_id: Int8;
  updated_at: Generated<Timestamp | null>;
  user_claimed: boolean | null;
  user_got_paid: boolean | null;
  user_selected_campaign: string | null;
  userid: string;
}

export interface AnalyticsTweetTopic {
  topic_id: number;
  tweet_id: Int8;
  updated_at: Generated<Timestamp | null>;
}

export interface AnalyticsTweetTranslation {
  body: Json | null;
  lang: string;
  model: string;
  tweet_id: Int8;
  tweet_text: string;
  updated_at: Generated<Timestamp | null>;
}

export interface AnalyticsUser {
  description: string | null;
  followers_count: number | null;
  following_count: number | null;
  like_count: number | null;
  listed_count: number | null;
  name: string | null;
  tweet_count: number | null;
  user_age_days: Numeric | null;
  user_created_at: Timestamp | null;
  user_fetched_at: Timestamp | null;
  user_id: Int8;
  username: string | null;
}

export interface AnalyticsUserAction {
  campaign_id: string | null;
  effective_date: Timestamp;
  event_name: string;
  tweet_id: Int8;
  updated_at: Generated<Timestamp | null>;
}

export interface AnalyticsUserEligibility {
  campaign_id: string | null;
  eligible_wallets: string[] | null;
  is_eligible: boolean | null;
  updated_at: Timestamp | null;
  user_id: Int8 | null;
}

export interface AnalyticsUserEval {
  body: Json | null;
  eval_id: number;
  updated_at: Generated<Timestamp | null>;
  user_id: Int8;
}

export interface AnalyticsUserEvalDef {
  definition: string;
  eval_id: Generated<number>;
  evaluator: string;
  parameters: Json | null;
  result_schema: Json | null;
  title: string;
  updated_at: Generated<Timestamp | null>;
}

export interface CreatorappAnalyticsTweet {
  content: string | null;
  conversation_id: Int8 | null;
  date_posted: Timestamp | null;
  impression_count: number | null;
  lang: string | null;
  like_count: number | null;
  media_json: Json | null;
  quote_count: number | null;
  quoted_tweet_id: Int8 | null;
  reply_count: number | null;
  reply_to_tweet_id: Int8 | null;
  retweet_count: number | null;
  source: string | null;
  tweet_id: Int8 | null;
  twitter_user_id: Int8 | null;
  user_uuid: string | null;
  visible: boolean | null;
}

export interface CreatorappAnalyticsTweetReward {
  campaign_uuid: string | null;
  campaign_week_id: string | null;
  impact_points: Numeric | null;
  is_in_top_n: boolean | null;
  quality_score: Numeric | null;
  receives_reward: boolean | null;
  reward_amount: Numeric | null;
  reward_amount_usd: Numeric | null;
  reward_currency: string | null;
  tweet_id: Int8 | null;
}

export interface CreatorappCampaignTweet {
  campaign_uuid: string | null;
  impact_points: Numeric | null;
  is_in_top_n: boolean | null;
  quality_score: Numeric | null;
  receives_reward: boolean | null;
  reward_amount: Numeric | null;
  reward_amount_usd: Numeric | null;
  reward_currency: string | null;
  tweet_id: Int8 | null;
}

export interface CreatorappTAdvertiser {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  advertiser_uuid: Generated<string>;
  /**
   * soft delete - only hides it from adminapp
   */
  deleted: Generated<boolean>;
  landing_hostname: string | null;
  name: string;
  theme: string | null;
}

export interface CreatorappTCampaign {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  advertiser_uuid: string;
  apy_bonus: Numeric | null;
  budget_currency: string;
  campaign_uuid: Generated<string>;
  campaign_week_id: string | null;
  date_end: Timestamp | null;
  date_maturity: Timestamp | null;
  date_start: Timestamp | null;
  date_visible_from: Timestamp | null;
  date_visible_to: Timestamp | null;
  description: string | null;
  description_html: string | null;
  geo_targeting_continents: Generated<string[] | null>;
  geo_targeting_countries: Generated<string[] | null>;
  geo_targeting_global: Generated<boolean>;
  min_account_age_days: number | null;
  min_followers: number | null;
  public: Generated<boolean>;
  segment_order_index: Generated<number>;
  signup_bonus: Numeric | null;
  signup_bonus_dedupe_id: string | null;
  title: string | null;
  total_budget: Numeric | null;
  user_top_n: number | null;
  wallet_address: string | null;
  welcome: string | null;
}

export interface CreatorappTCampaignRestriction {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  additional_check_type: string | null;
  campaign_uuid: string;
  min_twitter_account_age_days: number | null;
  min_twitter_followers: number | null;
}

export interface CreatorappTCampaignTip {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  campaign_tip_uuid: Generated<string>;
  campaign_uuid: string;
  content: string;
  priority: Generated<number>;
  type: string;
}

export interface CreatorappTCampaignWeek {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  advertiser_uuid: string;
  budget_currency: string;
  campaign_week_id: string;
  date_end: Timestamp;
  date_maturity: Timestamp;
  date_start: Timestamp;
  date_visible_from: Timestamp;
  date_visible_to: Timestamp;
  description_html: string;
  has_global_segment: Generated<boolean>;
  /**
   * Minimum of the min_account_age_days of campaign segments
   */
  min_min_account_age_days: number | null;
  /**
   * Minimum of the min_followers of campaign segments
   */
  min_min_followers: number | null;
  public: Generated<boolean>;
  staking_from_slot: Int8 | null;
  staking_min_amount: Numeric | null;
  staking_programs: ArrayType<AdminappStakingProgram> | null;
  staking_to_slot: Int8 | null;
  suggested_donts: Generated<string[]>;
  suggested_dos: Generated<string[]>;
  title: string;
  total_budget: Numeric | null;
  usd_conversion_rate: Generated<Numeric>;
  user_top_n: Generated<number>;
  wallet_address: string | null;
  welcome: string;
}

export interface CreatorappTCampaignWeekUserEligibility {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  account_created_at: Timestamp | null;
  campaign_week_id: string;
  eligible_segment_campaign_id: string | null;
  info: Json | null;
  ip_address_anonymous: boolean | null;
  ip_address_continent_code: string | null;
  ip_address_country_code: string | null;
  num_followers: number | null;
  participated: Generated<boolean>;
  state: CreatorappCampaignWeekUserEligibilityState;
  twitter_user_id: Int8;
  user_uuid: string;
}

export interface CreatorappTCampaignWeekUserWallet {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  campaign_week_id: string;
  eligibility_balance: Numeric | null;
  is_eligible: Generated<boolean>;
  user_uuid: string;
  wallet_uuid: string;
}

export interface CreatorappTConversionEvent {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  event_date: Timestamp;
  event_source: string;
  event_type: string;
  event_uuid: Generated<string>;
  idempotency_key: string;
  metadata_json: Json | null;
  user_uuid: string | null;
  wallet_id: string | null;
  wallet_uuid: string | null;
}

export interface CreatorappTCryptoPrice {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  crypto_price_uuid: Generated<string>;
  price_usd: Numeric;
  symbol: string;
  volume_24h_usd: Numeric;
}

export interface CreatorappTEmailQueue {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  content_html: string | null;
  email_uuid: Generated<string>;
  notification_uuid: string | null;
  recipient: string;
  recipient_user_uuid: string | null;
  state: string;
  subject: string | null;
  template_name: string | null;
  template_vars_json: Json | null;
}

export interface CreatorappTExternalAuth {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  api_key: string;
  api_secret: string | null;
  external_auth_uuid: Generated<string>;
  name: string;
}

export interface CreatorappTInvitation {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  campaign_uuid: string;
  date_valid_from: Timestamp;
  date_valid_to: Timestamp;
  invitation_uuid: Generated<string>;
  public_token: string;
  twitter_user_id: Int8;
}

export interface CreatorappTNotification {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  content: string;
  cta_json: Json | null;
  date_effective: Timestamp;
  dedupe_string: string;
  notification_uuid: Generated<string>;
  read: Generated<boolean>;
  recipient_user_uuid: string;
  sent: Generated<boolean>;
  type: string;
}

export interface CreatorappTSpoofingToken {
  admin_creator_user_uuid: string;
  admin_user_id: string;
  created_at: Generated<Timestamp>;
  expires_at: Timestamp;
  hash: string;
  spoofed_creator_user_uuid: string;
  spoofing_token_id: Generated<string>;
  used_at: Timestamp | null;
}

export interface CreatorappTTransaction {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  amount: Numeric;
  bonus_uuid: string | null;
  campaign_uuid: string | null;
  currency: string;
  note: string | null;
  transaction_uuid: Generated<string>;
  tweet_id: Int8 | null;
  type: string;
  user_uuid: string;
  wallet_uuid: string | null;
  withdrawal_uuid: string | null;
}

export interface CreatorappTTweetFromApp {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  content: string;
  date_posted: Timestamp;
  posted_for_campaign: string | null;
  tweet_id: Int8;
  twitter_user_id: Int8;
}

export interface CreatorappTTweetState {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  date_evaluated: Timestamp | null;
  date_expires: Timestamp | null;
  media_fetched_at: Timestamp | null;
  media_json: Json | null;
  state: string;
  tweet_id: Int8;
  twitter_user_id: Int8 | null;
  user_uuid: string | null;
  visible: Generated<boolean>;
  was_eligible: Generated<boolean>;
}

export interface CreatorappTTwitterAuthTokens {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  access_token: string;
  access_token_expires_at: Timestamp;
  grant_scopes: string[];
  refresh_token: string | null;
  twitter_user_id: Int8;
}

export interface CreatorappTTwitterLoginTokens {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  login_code_verifier: string;
  login_state: string;
  url_callback: string;
  url_redirect_after_login: string | null;
  url_redirect_on_cancel_login: string | null;
}

export interface CreatorappTTwitterUser {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  account_created_at: Timestamp | null;
  description: string | null;
  handle: string | null;
  location: string | null;
  name: string | null;
  num_followers: number | null;
  num_statuses: Numeric | null;
  profile_pic_url: string | null;
  twitter_user_id: Int8;
  url: string | null;
}

export interface CreatorappTTwitterUserBlacklist {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  blacklist_uuid: Generated<string>;
  campaign_uuid: string;
  note: string | null;
  twitter_user_id: Int8;
}

export interface CreatorappTUser {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  allow_email_notifications: Generated<boolean>;
  cookie_preferences: string | null;
  date_last_login: Timestamp | null;
  email: string | null;
  name: string;
  preferred_advertiser_updated_at: Timestamp | null;
  preferred_advertiser_uuid: string | null;
  roles: string[];
  tutorial_preferences: string | null;
  twitter_user_id: Int8 | null;
  two_factor_enabled: Generated<boolean>;
  two_factor_secret: string | null;
  user_uuid: Generated<string>;
  verified_email: string | null;
  verified_two_factor_enabled: Generated<boolean>;
}

export interface CreatorappTUserBonus {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  amount: Numeric;
  bonus_dedupe_id: string;
  bonus_uuid: Generated<string>;
  campaign_uuid: string | null;
  currency: string;
  note: string | null;
  state: string;
  user_uuid: string;
}

export interface CreatorappTUserEligibilityCheck {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  campaign_uuid: string;
  check_uuid: Generated<string>;
  client_ip: string | null;
  deleted: Generated<boolean>;
  note: string | null;
  origin: string;
  relevance_score: Numeric | null;
  state: string;
  user_uuid: string;
  wallet_uuid: string | null;
}

export interface CreatorappTUserLogin {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  client_ip: string | null;
  client_metadata_json: Json | null;
  login_uuid: Generated<string>;
  user_uuid: string;
}

export interface CreatorappTUserSessionIpAddress {
  _created_at: Generated<Timestamp | null>;
  id: Generated<number>;
  ip_address: string;
  session_id: string;
  user_uuid: string;
}

export interface CreatorappTUserSpoofing {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  admin_user_uuid: string;
  spoofed_user_uuid: string;
}

export interface CreatorappTUserToken {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  prev_refresh_token: string | null;
  refresh_token: string;
  user_uuid: string;
}

export interface CreatorappTUserWallet {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  address: string;
  caip_address: string;
  chain_id: string;
  deleted: Generated<boolean>;
  name: string | null;
  state: CreatorappWalletState;
  user_uuid: string;
  verification_detail: string | null;
  verification_type: CreatorappWalletVerificationType | null;
  verification_user_id: string | null;
  wallet_type_uuid: string;
  wallet_uuid: Generated<string>;
}

export interface CreatorappTWalletType {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  address_regexp: string;
  chain_id: string;
  currencies: string[];
  estimated_fee_rate: Generated<Numeric>;
  name: string;
  wallet_type_uuid: Generated<string>;
}

export interface CreatorappTWalletVerification {
  _created_at: Generated<Timestamp>;
  _updated_at: Generated<Timestamp>;
  completed: Generated<boolean>;
  full_text_to_sign: string;
  verification_token: string;
  wallet_uuid: string;
  wallet_verification_uuid: Generated<string>;
}

export interface CreatorappTWithdrawal {
  _created_at: Generated<Timestamp | null>;
  _updated_at: Generated<Timestamp | null>;
  amount: Numeric;
  currency: string;
  date_settled: Timestamp | null;
  reason: string | null;
  sender_user_uuid: string;
  settled_transaction_id: string | null;
  state: string;
  target_wallet_address: string;
  target_wallet_type: string;
  two_factor_secret: string | null;
  withdrawal_uuid: Generated<string>;
}

export interface GlobalApiSessions {
  admin_user_id: string | null;
  created_at: Generated<Timestamp>;
  creator_user_id: string | null;
  expires_at: Timestamp;
  /**
   * sha256 hash of session secret. Used so that person with access to database cannot impersonate users just by using session id
   */
  hash: string;
  ip_address: string | null;
  login_type: GlobalLoginTypeEnum | null;
  session_id: Generated<string>;
  started_by_admin_login_token: string | null;
  updated_at: Generated<Timestamp>;
}

export interface GlobalCurrencies {
  code: string;
  scale: number;
}

export interface GlobalIpAddressGeo {
  continent_code: string | null;
  country_code: string | null;
  created_at: Generated<Timestamp | null>;
  ip_address: string;
  is_anonymous: boolean;
  updated_at: Generated<Timestamp | null>;
}

export interface GlobalIpAddressGeoRaw {
  created_at: Generated<Timestamp | null>;
  data: Json | null;
  id: Generated<number>;
  ip_address: string;
}

export interface GraphileMigrateCurrent {
  content: string;
  date: Generated<Timestamp>;
  filename: Generated<string>;
}

export interface GraphileMigrateMigrations {
  date: Generated<Timestamp>;
  filename: string;
  hash: string;
  previous_hash: string | null;
}

export interface IngestionBlockchainTransactions {
  data: Json | null;
  programs: Generated<string[] | null>;
  signature: string;
  signer: string | null;
  slot: Int8;
  updated_at: Generated<Timestamp | null>;
}

export interface IngestionRegisteredProgramsInstructions {
  accounts: Generated<string[] | null>;
  instruction_id: string;
  params: Json | null;
  program_id: string;
  sequence_number: number;
  signature: string;
  updated_at: Generated<Timestamp | null>;
}

export interface IngestionTransactionBalanceChanges {
  balance_change: Numeric | null;
  signature: string;
  token_address: string;
  updated_at: Generated<Timestamp | null>;
}

export interface IngestionTransactionQueries {
  created_at: Generated<Timestamp | null>;
  origin_address: string | null;
  query_completed: Generated<boolean | null>;
  signature: string;
}

export interface LogsTweetErrors {
  _created_at: Generated<Timestamp | null>;
  attachments: Json | null;
  errors: Json;
  headers: Json;
  log_id: Generated<string>;
  operation_id: string | null;
  reason: string;
  response_code: number;
  text_content: string;
  twitter_user_id: Int8;
}

export interface LogsTweetUpdates {
  attempt: number;
  created_at: Generated<Timestamp | null>;
  data: Json | null;
  job_id: Int8;
  log_id: Generated<string>;
  state: string | null;
  tweet_id: Int8 | null;
  twitter_user_id: Int8 | null;
}

export interface DB {
  "adminapp.advertiser_available_currencies": AdminappAdvertiserAvailableCurrencies;
  "adminapp.advertiser_twitter_tokens": AdminappAdvertiserTwitterTokens;
  "adminapp.advertisers": AdminappAdvertisers;
  "adminapp.asset": AdminappAsset;
  "adminapp.campaign_series": AdminappCampaignSeries;
  "adminapp.campaign_week_asset": AdminappCampaignWeekAsset;
  "adminapp.campaign_week_payment_requests": AdminappCampaignWeekPaymentRequests;
  "adminapp.campaign_week_segments": AdminappCampaignWeekSegments;
  "adminapp.campaign_weeks": AdminappCampaignWeeks;
  "adminapp.login_tokens": AdminappLoginTokens;
  "adminapp.role_privileges": AdminappRolePrivileges;
  "adminapp.segments": AdminappSegments;
  "adminapp.user_roles": AdminappUserRoles;
  "adminapp.user_spoofing": AdminappUserSpoofing;
  "adminapp.users": AdminappUsers;
  "analytics.api_request": AnalyticsApiRequest;
  "analytics.campaign": AnalyticsCampaign;
  "analytics.raw_media": AnalyticsRawMedia;
  "analytics.raw_tweet": AnalyticsRawTweet;
  "analytics.raw_user": AnalyticsRawUser;
  "analytics.topic": AnalyticsTopic;
  "analytics.tweet": AnalyticsTweet;
  "analytics.tweet_en": AnalyticsTweetEn;
  "analytics.tweet_eval": AnalyticsTweetEval;
  "analytics.tweet_eval_def": AnalyticsTweetEvalDef;
  "analytics.tweet_metrics": AnalyticsTweetMetrics;
  "analytics.tweet_metrics_recent": AnalyticsTweetMetricsRecent;
  "analytics.tweet_reference": AnalyticsTweetReference;
  "analytics.tweet_reward": AnalyticsTweetReward;
  "analytics.tweet_reward_candidate": AnalyticsTweetRewardCandidate;
  "analytics.tweet_state": AnalyticsTweetState;
  "analytics.tweet_state_audit": AnalyticsTweetStateAudit;
  "analytics.tweet_topic": AnalyticsTweetTopic;
  "analytics.tweet_translation": AnalyticsTweetTranslation;
  "analytics.user": AnalyticsUser;
  "analytics.user_action": AnalyticsUserAction;
  "analytics.user_eligibility": AnalyticsUserEligibility;
  "analytics.user_eval": AnalyticsUserEval;
  "analytics.user_eval_def": AnalyticsUserEvalDef;
  "creatorapp.analytics_tweet": CreatorappAnalyticsTweet;
  "creatorapp.analytics_tweet_reward": CreatorappAnalyticsTweetReward;
  "creatorapp.campaign_tweet": CreatorappCampaignTweet;
  "creatorapp.t_advertiser": CreatorappTAdvertiser;
  "creatorapp.t_campaign": CreatorappTCampaign;
  "creatorapp.t_campaign_restriction": CreatorappTCampaignRestriction;
  "creatorapp.t_campaign_tip": CreatorappTCampaignTip;
  "creatorapp.t_campaign_week": CreatorappTCampaignWeek;
  "creatorapp.t_campaign_week_user_eligibility": CreatorappTCampaignWeekUserEligibility;
  "creatorapp.t_campaign_week_user_wallet": CreatorappTCampaignWeekUserWallet;
  "creatorapp.t_conversion_event": CreatorappTConversionEvent;
  "creatorapp.t_crypto_price": CreatorappTCryptoPrice;
  "creatorapp.t_email_queue": CreatorappTEmailQueue;
  "creatorapp.t_external_auth": CreatorappTExternalAuth;
  "creatorapp.t_invitation": CreatorappTInvitation;
  "creatorapp.t_notification": CreatorappTNotification;
  "creatorapp.t_spoofing_token": CreatorappTSpoofingToken;
  "creatorapp.t_transaction": CreatorappTTransaction;
  "creatorapp.t_tweet_from_app": CreatorappTTweetFromApp;
  "creatorapp.t_tweet_state": CreatorappTTweetState;
  "creatorapp.t_twitter_auth_tokens": CreatorappTTwitterAuthTokens;
  "creatorapp.t_twitter_login_tokens": CreatorappTTwitterLoginTokens;
  "creatorapp.t_twitter_user": CreatorappTTwitterUser;
  "creatorapp.t_twitter_user_blacklist": CreatorappTTwitterUserBlacklist;
  "creatorapp.t_user": CreatorappTUser;
  "creatorapp.t_user_bonus": CreatorappTUserBonus;
  "creatorapp.t_user_eligibility_check": CreatorappTUserEligibilityCheck;
  "creatorapp.t_user_login": CreatorappTUserLogin;
  "creatorapp.t_user_session_ip_address": CreatorappTUserSessionIpAddress;
  "creatorapp.t_user_spoofing": CreatorappTUserSpoofing;
  "creatorapp.t_user_token": CreatorappTUserToken;
  "creatorapp.t_user_wallet": CreatorappTUserWallet;
  "creatorapp.t_wallet_type": CreatorappTWalletType;
  "creatorapp.t_wallet_verification": CreatorappTWalletVerification;
  "creatorapp.t_withdrawal": CreatorappTWithdrawal;
  "global.api_sessions": GlobalApiSessions;
  "global.currencies": GlobalCurrencies;
  "global.ip_address_geo": GlobalIpAddressGeo;
  "global.ip_address_geo_raw": GlobalIpAddressGeoRaw;
  "graphile_migrate.current": GraphileMigrateCurrent;
  "graphile_migrate.migrations": GraphileMigrateMigrations;
  "ingestion.blockchain_transactions": IngestionBlockchainTransactions;
  "ingestion.registered_programs_instructions": IngestionRegisteredProgramsInstructions;
  "ingestion.transaction_balance_changes": IngestionTransactionBalanceChanges;
  "ingestion.transaction_queries": IngestionTransactionQueries;
  "logs.tweet_errors": LogsTweetErrors;
  "logs.tweet_updates": LogsTweetUpdates;
}
